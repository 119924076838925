import React from "react"
import Header from "../../Header"
import BeastPlatformer from "../../images/BeastPlatformer.png"
import BelleDoor from "../../images/BelleDoor.png"
import BeastSprite from "../../images/BeastSprite.png"
import { Link } from "gatsby";

export default function BeastsAdventure() {
    return (
        <>
            <Header noog={true} title="Blog - Building a Game With the Kids"
                description="Building Beast's Adventure with my seven and five year old"
                image={"https://johnhaldeman.ca" + BeastPlatformer}
            ></Header>
            <p style={{"textAlign": "right"}}>Posted June 2, 2020</p>
            <h3>Building a Game With the Kids</h3>
            <p>
                My kids like video games. Really, who doesn’t? But this post isn’t about kids playing video games, 
                this is about kids building video games (with some help from their dad). My eldest daughter 
                came up to me a little while ago and said that she might like to build video games one day 
                when she grows up. My son told me that maybe he might want to be a software engineer (I think mostly 
                because there’s a great Story Bots episode on it).
            </p>
            <p>
                My kids are young. My daughter is seven and my son is five. My son is just starting to learn to 
                read so he can’t program. My daughter is currently achingly slow with a keyboard, so she would have a lot of trouble too. 
                So, I decided I would do all the coding and they would do everything else. My 
                daughter would come up with the game design and do all the art (if you’ve ever built a game 
                before, you know there’s a lot of art content that goes into it). My son would do a lot of the testing 
                by playing the game and provide suggestions. We could also record him doing the sounds (in 
                the end they both helped do the sound effects).
            </p>
            <p>
                The result is interesting combination of very short platformer and point and click adventure 
                game called "Beast’s Adventure". You can view a playthrough below.
                <iframe id="ytplayer" type="text/html" width="800" height="600"
                src="https://www.youtube.com/embed/0unv1tC13cA"
                frameborder="0"></iframe>
                It’s a glimpse into the mind of a seven-year-old. I didn’t do anything except the coding 
                and taking direction from my daughter - the game designer. Here's a breakdown of how we built it.
            </p>
            <p>
                My daughter started by drawing. This is easy for her. She draws and colours for a couple of 
                hours daily. Her work from school is littered with doodles. Often, she creates her own 
                picture books. It's constant. Here's some examples of the concept art she added for the game:
            </p>
            <a href={BeastPlatformer}><img src={BeastPlatformer} width="70%" className="center"></img></a>
            <p className="img-caption">Concept Drawing of the Platformer Section of the Game</p>
            <a href={BelleDoor}><img src={BelleDoor} width="40%" className="center"></img></a>
            <p className="img-caption">Concept Drawing of the Door Opening Section – she later decided to get rid of the red blob</p>
            <p>
                While she did the concept art, I took to learning the basics of <a href="https://phaser.io/">Phaser </a>which is a 
                great little 2D HTML 5 game engine. Luckily they have a <a href="https://phaser.io/tutorials/making-your-first-phaser-3-game/part1">tutorial </a>
                to get you started that conveniently was based around how to make a simple platformer. 
                While it eventually was changed quite a bit, I based our game around the code in the tutorial.
            </p>
            <p>
                I proceeded to turn my daughter’s concept drawings into individual images or into sprite 
                sheets for animation when needed. This took a modest amount of work using <a href="https://www.microsoft.com/en-ca/p/paint-3d/9nblggh5fv99">MS 
                Paint 3D</a> when I could and <a href="https://www.gimp.org/">Gimp</a> when I needed to do something more advanced.
            </p>
            <a href={BeastSprite}><img src={BeastSprite} width="100%" className="center"></img></a>
            <p className="img-caption">Beast Animated Sprite for Walking</p>
            <p>
                Once the artwork was all separated and the game programmed, the next thing was the sounds. 
                This was simple enough to do with the built-in <a href="https://www.microsoft.com/en-ca/p/windows-voice-recorder/9wzdncrfhwkn">Windows Voice Recorder</a>.
            </p>
            <p>
                From here it was just about playing and experimenting. What if we make Beast jump really high? 
                What if we turn off gravity? What if we reverse gravity? What if we make Beast run super-fast? 
                This was the way I could show them a little bit about how the mechanics worked under the covers. 
                They had a great time. If you want to play it, you can find it here, but you’ll need a computer 
                with a keyboard:
            </p>
            <p>
                <a href="https://johnhaldeman.ca/beastsadventure/">https://johnhaldeman.ca/beastsadventure/</a>
            </p>
            <p style={{textAlign: "center"}}>
                <Link to="/posts/remote-in-windsor"><button>Previous Post</button></Link>&nbsp;
                <Link to="/posts/ready-uppy"><button>Next Post</button></Link>
            </p>
        </>
    )
}